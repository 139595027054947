import React from 'react';
import { Helmet } from 'react-helmet';

import { StaticQuery, graphql } from 'gatsby';
import { checkFile } from 'utils/common';

const SEO = ({seo, title, url = ''}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const setting = data.setting.frontmatter;
        const webname = setting.web_name;

        const weburl = setting.web_url.endsWith('/')
          ? setting.web_url.slice(0, -1)
          : setting.web_url;

        let seo_image = '';
        if (seo.seo_image && seo.seo_image !== null && seo.seo_image.publicURL ) {
          seo_image = `${weburl}${checkFile(seo.seo_image.publicURL)}`;
        }

        const seoPrint = {
          desc: seo.seo_shortdesc,
          keywords: seo.seo_keywords,
          image: seo_image,
          url: url,
        };
        return (
        <Helmet>

          {(seoPrint.desc !== null || seoPrint.desc !== '') && 
              <meta name='description' content={seoPrint.desc} />
          }
          {(seoPrint.desc !== null || seoPrint.desc !== '') && 
              <meta property='og:description' content={seoPrint.desc} />

          }
          {(seoPrint.desc !== null || seoPrint.desc !== '') && 
              <meta name='twitter:description' content={seoPrint.desc} />

          }

          {(seoPrint.url !== null || seoPrint.url !== '') && <meta property='og:url' content={seoPrint.url} />}
          {(seoPrint.url !== null || seoPrint.url !== '') && <link rel="canonical" href={seoPrint.url} />}

          {(seoPrint.image !== null || seoPrint.image !== '') && (
            <meta name='image' content={seoPrint.image} />
        
          )} {(seoPrint.image !== null || seoPrint.image !== '') && (
            <meta name='twitter:image' content={seoPrint.image} />
          )} {(seoPrint.image !== null || seoPrint.image !== '') && (
            <meta property='og:image' content={seoPrint.image} />
          )}

          {(seoPrint.keywords !== null || seoPrint.keywords !== '') && (
            <meta name='keywords' content={seoPrint.keywords} />
          )}
        </Helmet>
    )
  }
  } 
    />
  );
}

export default SEO;

const query = graphql`
  query {
    setting: markdownRemark(
      frontmatter: {
        issetting: { eq: true }
        contenttype: { eq: "general_setting" }
      }
    ) {
      html
      frontmatter {
        web_name
        web_url
      }
    }
  }
`;
